import React, { Component } from 'react';
import { hot } from 'react-hot-loader/root';
import HelloWorld from './components/hello-world';
import _ from 'lodash';

class App extends Component {
  constructor(props) {
    super(props);
    this.state = { value: "" };
  }

  handleChange(event) {
    const value = event.target.value;
    this.setState({ value: value });

    fetch(`https://siae9aghj8.execute-api.us-east-1.amazonaws.com/dev/suggestions?prefix=${value}`)
      .then((r) => {
        if (r.ok) {
          return r.json();
        } else {
          throw "Request failed!";
        }
      })
      .then((data) => this.setState({ suggestions: _.join(data.words, ", ") }))
  }

  render() {
    return (
      <div>
        <input type="text" value={this.state.value} onChange={(e) => this.handleChange(e)} />

        <p>{this.state.suggestions}</p>
      </div>
    );
  }
}

export default hot(App);
